import Service from "./Service";
import {isEmpty} from "lodash";
const baseurl = '/api/registro-pai';
export default {
    index(params={}) {
        return Service.get(`${baseurl}`,{
            params: params
        });
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    async showByIdUser(search = '',pagination = {},id) {
        let url = `${baseurl}/show-by-idUser/${id}?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    countVacunas(params={}) {
        return Service.get(`${baseurl}/count-vacunas`,{
            params: params
        });
    },
    countBiologicos(params={}) {
        return Service.get(`${baseurl}/count-biologicos`,{
            params: params
        });
    },
    graficoVacunas(range) {
        return Service.get(`${baseurl}/grafico-vacunas`, {
            params : {
                ...range,
            }
        });
    },
    graficoVacunasDosis(range) {
        return Service.get(`${baseurl}/grafico-vacunas-dosis`, {
            params : {
                ...range
            }
        });
    },
    graficoVacunasDay(range) {
        return Service.get(`${baseurl}/grafico-vacunas-day`, {
            params : {
                ...range,
            }
        });
    },
    reportGeneralVacunas(start_date,end_date, tipo) {

        return window.open(
            `${process.env.VUE_APP_BASE_PATH}${baseurl}/report-general/${start_date}/${end_date}?tipo=${tipo}`,
        );
    },
}
