<template>
    <main>
        <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                            Registro PAI
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-xl px-4 mt-2">
<!--            <div class="row" v-for="(item,index) in cantidadBiologicos" :key="index">
                <div class="col-xl-3 mb-4">
                    <router-link :to="{name : 'dashboard'}">
                        <div class="card lift lift-sm h-100 border-left-lg border-left-primary">
                            <div class="card-body d-flex justify-content-center flex-column">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="">
                                        <div class="text-primary mb-1">
                                            Estadisticas PAI
                                        </div>
                                    </div>
                                    <img src="@/assets/img-generales/charts.png" alt="..." style="width: 6rem">
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>-->
            <div class="row gutters-sm">
                <div class="col-12 mb-3">
                    <div class="card text-center card-waves mt-2 card-header-actions">
                        <div class="card-header">
                            Usuario
                            <usuario-field v-model="usuario" :validator="$v.usuario" :showName="false"></usuario-field>
                        </div>
                        <div class="card-body">
                            <div v-if="Object.keys(usuario).length">
                                <div class="form-group">
                                    <div v-if="usuario.sexo === 'F'">
                                        <img class="img-fluid " src="@/assets/img-genero/mujer.png" width="96">
                                    </div>
                                    <div  v-else-if="usuario.sexo === 'M'">
                                        <img class="img-fluid" src="@/assets/img-genero/hombre.png" width="96">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <strong >{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</strong>
                                    <h6 class="text-body mt-2">FECHA DE NACIMIENTO: {{usuario.fecha_nacimiento}}</h6>
                                    <h6 class="text-body">EDAD : <span class="badge badge-secondary">{{usuario.years}}</span> </h6>
                                </div>
                                <div class="form-group mt-2">
                                    <button class="btn btn-success btn-sm lift lift-sm" @click="registrar();">Registrar Vacunas <i class="fa fa-plus fa-fw"></i></button>
                                </div>
                            </div>
                            <div class="align-center " v-else >
                                <img class="mb-0" src="@/assets/img-generales/error.png" width="256">
                                <p ><strong>Sin datos para mostrar...</strong></p>
                            </div>
                        </div>
                    </div>
                     <!--card-grafico-dosis-vacunas :vacunas="vacunas"></card-grafico-dosis-vacunas-->
                </div>
<!--                <div class="col-lg-8 col-md-8"  >
                    <card-grafico-vacunas :vacunas="vacunas"></card-grafico-vacunas>
                    <card-grafico-vacunas-day ></card-grafico-vacunas-day>
                </div>-->
            </div>
        </div>
    </main>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import Swal from "sweetalert2";
import UsuarioField from "../../components/common/UsuarioField";
import {isEmpty} from "lodash";
import moment from "moment";
import registroPaiService from "../../services/regitroPaiService";
//import CardGraficoDosisVacunas from './cardGraficoDosisVacunas';
//import cardGraficoVacunas from './cardGraficoVacunas';
//import cardGraficoVacunasDay from './cardGraficoVacunasDay';
export default {
    name: "index.vue",
    components: { UsuarioField },
    data () {
        return {
            usuario : {},
            cantidadBiologicos:[],
            vacunas: [],
        }
    },

    filters: {
        upper: function (value) {
            return value.toUpperCase();
        },
    },
    validations: {
        usuario : {required},
    },
    methods: {
        async registrar(){
            try {
                this.LoaderSpinnerShow();
                this.$router.push({
                    name: 'pail.perfil.usuario', params: { idUsuario:this.usuario.id }
                });
                this.LoaderSpinnerHide();

            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Swal.fire('Ups!', 'ha ocurrido un error al procesar la solicitud', 'error');
            }
        },
        async countBiologicos() {
            const response = await registroPaiService.countBiologicos();
            this.cantidadBiologicos= response.data;
        },
        async cargarVacunas(){
            const response = await registroPaiService.index()
            this.vacunas = response.data.map(x => {
                return {
                    id : x.id,
                    nombre : x.nombre,
                }
            });
        },
    },
    created() {
        this.countBiologicos();
        this.cargarVacunas();
    },
    computed: {
        edad: function () {
            if (isEmpty(this.usuarios.fecha_nacimiento)) {
                return "";
            }

            return moment()
                .diff(moment(this.usuarios.fecha_nacimiento, "YYYY-MM-DD"), "year")
                .toString();
        },
        contratos_eps :  function(){
            if(isEmpty(this.usuarios.eps)) return [];
            return this.usuarios.eps.contratos;
        }
    },
}
</script>
